<template>
    <div :key="'table-'+reloadTable" class="card" :class="cardClass" :style="cardStyle" v-if="pageLoaded">

        <!-- HEADER -->

        <div class="card-header" :class="headerClass" :style="headerStyle"
             v-on:click="collapseTable"
             v-if="showHeader">
            <table style="border-collapse: collapse; width: 100%;">
                <tr>
                    <template v-if="title">
                        <td class="pl-2" style="width: 30px;" v-if="collapsible">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" size="sm" v-if="isCollapsed"/>
                            <font-awesome-icon :icon="['fas', 'chevron-down']" size="sm" v-else/>
                        </td>
                        <td class="pl-2" style="width: 30px;" v-else-if="titleIcon">
                            <font-awesome-icon :icon="titleIcon" size="sm"/>
                        </td>
                        <td class="pl-2">
                            {{ title }}
                        </td>
                    </template>
                    <template v-else-if="!state.readonly">
                        <td>
                            <div class="input-group input-group-sm">
                                <template v-for="(action,actionIndex) in crudActions">
                                    <template v-if="!action.hideintable && action.placement === 'TABLEHEADERLEFT'">
                                        <div :key="action.name+'-'+actionIndex" class="input-group-append">
                                            <ButtonAction :action="action" :disabled="state.disabled" :parameter-data="parameterData" forward-events
                                                          v-on:forward-event="handleForwardedActionEvent(action, $event)"
                                                          v-if="action.type === 'BUTTON'"/>
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </td>
                    </template>
                    <template v-if="!state.readonly">
                        <td>
                            <div class="float-right">
                                <div class="input-group input-group-sm">
                                    <template v-for="(action,actionIndex) in crudActions">
                                        <template v-if="!action.hideintable && action.placement === 'TABLEHEADERRIGHT'">
                                            <div :key="action.name+'-'+actionIndex" class="input-group-append">
                                                <ButtonAction :action="action" :disabled="state.disabled" :parameter-data="parameterData" forward-events
                                                              v-on:forward-event="handleForwardedActionEvent(action, $event)"
                                                              v-if="action.type === 'BUTTON'"/>
                                                <ToggleAction :action="action" :disabled="state.disabled" forward-events
                                                              v-on:forward-event="handleForwardedActionEvent(action, $event)"
                                                              v-if="action.type === 'TOGGLE'"/>
                                            </div>
                                        </template>
                                    </template>
                                </div>
                            </div>
                        </td>
                    </template>
                </tr>
            </table>
        </div>

        <!-- BODY -->

        <div class="card-body" :class="bodyClass" style="padding: 1rem;" :style="bodyStyle" v-if="!isCollapsed">

            <!-- FILTER -->

            <template v-if="withFilter">
                <div class="input-group input-group-sm mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <font-awesome-icon :icon="['fas', 'search']" size="sm"/>
                        </span>
                    </div>
                    <div class="input-group-append" style="flex-grow: 1;">
                        <b-form-input type="search" ref="filter" v-model="filter"
                                      :placeholder="$i18n.tc('translations.Filter',1)" :disabled="state.disabled" :autofocus="autofocus"
                                      v-on:input="debounceInput"/>
                    </div>
                    <template v-for="(action,actionIndex) in crudActions">
                        <template v-if="!action.hideintable && action.placement === 'FILTERRIGHT'">
                            <div :key="action.name+'-'+actionIndex" class="input-group-append">
                                <ButtonAction :action="action" :disabled="state.disabled" :parameter-data="parameterData" forward-events
                                              v-on:forward-event="handleForwardedActionEvent(action, $event)"
                                              v-if="action.type === 'BUTTON'"/>
                                <DropDownAction :action="action" :disabled="state.disabled" :parameter-data="parameterData" forward-events
                                                v-on:forward-event="handleForwardedActionEvent(action, $event)"
                                                v-if="action.type === 'DROPDOWN'"/>
                                <ToggleAction :action="action" :disabled="state.disabled" forward-events
                                              v-on:forward-event="handleForwardedActionEvent(action, $event)"
                                              v-if="action.type === 'TOGGLE'"/>
                            </div>
                        </template>
                    </template>
                </div>
            </template>

            <table class="table table-hover table-bordered table-striped table-sm m-0 table-highlight">

                <!-- TABLE HEADER -->

                <thead>
                <tr>
                    <template v-for="(field,index) in crudFields">
                        <template v-if="!field.hideintable && (field.showintable || field.editintable)">
                            <th :key="'tableheader-' + field.name + '-' + index" :class="field.thclass" :style="field.thstyle"
                                v-if="field.thicon">
                                <font-awesome-icon :icon="field.thicon" size="sm"/>
                            </th>
                            <th :key="'tableheader-' + field.name + '-' + index" :class="field.thclass" :style="field.thstyle"
                                v-else-if="field.type === 'ID'">
                                <input type="checkbox" title="select" v-model="selectAll"
                                       v-on:change="toggleAll">
                            </th>
                            <th :key="'tableheader-' + field.name + '-' + index" class="p-0" :class="field.thclass" :style="field.thstyle"
                                v-else-if="'stateintable' in field">
                                <button type="button" class="btn rounded-0 p-0" style="width: 100%; font-weight: bold; text-align: left;"
                                        v-on:click="changeStateInTable(field)">
                                    <font-awesome-icon :icon="['fas','minus']" size="sm" style="width: 20px;" v-if="field.stateintable === 0"/>
                                    <font-awesome-icon :icon="['fas','check']" size="sm" style="width: 20px;" v-if="field.stateintable === 1"/>
                                    <font-awesome-icon :icon="['fas','xmark']" size="sm" style="width: 20px;" v-if="field.stateintable === 2"/>
                                    {{ field.label }}
                                </button>
                            </th>
                            <th :key="'tableheader-' + field.name + '-' + index" :class="field.thclass" :style="field.thstyle"
                                v-else>
                                {{ field.label }}
                            </th>
                        </template>
                    </template>
                    <template v-if="showActions && !state.readonly">
                        <th v-if="noActionsLabel" :class="thActionsClass" :style="thActionsStyle"/>
                        <th v-else class="text-center" style="width: 135px;" :class="thActionsClass" :style="thActionsStyle">
                            <font-awesome-icon :icon="['fas', 'cogs']" size="sm"/>
                            {{ $i18n.t('translations.Actions') }}
                        </th>
                    </template>
                </tr>
                </thead>

                <!-- TABLE BODY -->

                <tbody :key="'table-body-'+reloadTableData">
                <template v-for="(row,rowIndex) in tableRows">
                    <tr :key="'tablerow-' + row.id + '-' + rowIndex + '-' + reloadRow"
                        :class="{'selected-row': (row.id === selectedRowId)}"
                        v-on:click="onClickTableRow(row)">
                        <template v-for="(field,fieldIndex) in crudFields">
                            <template v-if="!field.hideintable && (field.showintable || field.editintable)">
                                <td :key="'td-' + rowIndex + '-' + fieldIndex"
                                    :class="field.tdclassfield ? row[field.tdclassfield] : field.tdclass"
                                    :style="field.tdstylefield ? row[field.tdstylefield] : field.tdstyle"
                                    :title="fieldTitle(field, row)">
                                    <Field :field="field" :data="row" :state="state"
                                           v-on:forward-event="handleForwardedFieldEvent(field, $event)"
                                           v-on:inline-edit="inlineEdit(rowIndex,$event)"
                                           v-on:toggle-row="toggleRow"/>
                                </td>
                            </template>
                        </template>
                        <template v-if="showActions && !state.readonly">
                            <td style="text-align: center;">
                                <template v-for="(action,actionIndex) in crudActions">
                                    <template v-if="!action.hideintable && (!action.hidefield || !row[action.hidefield])">
                                        <ButtonAction :key="action.name + '-' + rowIndex + '-' + actionIndex"
                                                      :action="action" :disabled="state.disabled || (action.disablefield && row[action.disablefield])" forward-events
                                                      v-on:forward-event="handleForwardedActionEventForRow(action, $event, row)"
                                                      v-if="action.type === 'BUTTON' && action.placement === 'TABLEROW'"/>
                                        <RouteAction :key="action.name + '-' + rowIndex + '-' + actionIndex" :action="action" :disabled="state.disabled" :data="row"
                                                     v-if="action.type === 'ROUTE' && action.placement === 'TABLEROW'"/>
                                    </template>
                                </template>
                            </td>
                        </template>
                    </tr>
                </template>

                <!-- TABLE TOTALS -->

                <template v-if="totalFields">
                    <tr>
                        <td class="st-total" :colspan="colSpan">
                            {{ $i18n.t('translations.Total') }}
                        </td>
                        <template v-for="(field,index) in totalFields">
                            <td :key="'td-totals-' + index"
                                :class="field.tdclassfield ? totals[field.tdclassfield] : field.tdclass"
                                :style="field.tdstylefield ? totals[field.tdstylefield] : field.tdstyle"
                                :title="fieldTitle(field, totals)">
                                <Field :field="field" :data="totals" :state="state"/>
                            </td>
                        </template>
                        <template v-if="showActions && !state.readonly">
                            <td/>
                        </template>
                    </tr>
                </template>
                </tbody>
            </table>

            <!-- PAGINATION -->

            <template v-if="meta && (paginationAlwaysVisible || meta.last_page > 1)">
                <ul class="pagination justify-content-center mt-3 mb-0">
                    <li class="page-item" :class="{'disabled' : meta.current_page === 1}">
                        <button class="page-link" v-on:click="firstPage">
                            <font-awesome-icon :icon="['fas', 'angle-double-left']" size="sm"/>
                        </button>
                    </li>
                    <li class="page-item" :class="{'disabled' : meta.current_page === 1}">
                        <button class="page-link" v-on:click="previousPage">
                            <font-awesome-icon :icon="['fas', 'angle-left']" size="sm"/>
                        </button>
                    </li>
                    <li class="page-item disabled">
                        <a class="page-link">
                            {{ meta.current_page }} {{ $i18n.t('translations.of') }} {{ meta.last_page }}
                            ({{ meta.total }}{{ numberOfSelectedRows > 0 ? ', ' + $i18n.tc('translations.selected', numberOfSelectedRows) : '' }})
                        </a>
                    </li>
                    <li class="page-item" :class="{'disabled' : meta.current_page === meta.last_page}">
                        <button class="page-link" v-on:click="nextPage">
                            <font-awesome-icon :icon="['fas', 'angle-right']" size="sm"/>
                        </button>
                    </li>
                    <li class="page-item" :class="{'disabled' : meta.current_page === meta.last_page}">
                        <button class="page-link" v-on:click="lastPage">
                            <font-awesome-icon :icon="['fas', 'angle-double-right']" size="sm"/>
                        </button>
                    </li>
                </ul>
            </template>

        </div>

        <!-- FOOTER -->

        <template v-if="!state.readonly">
            <div class="card-footer" :class="footerClass" :style="footerStyle" v-if="!isCollapsed && showFooter">
                <table style="border-collapse: collapse; width: 100%;">
                    <tr>
                        <td>
                            <template v-for="(action,actionIndex) in crudActions">
                                <template v-if="!action.hideintable && action.placement === 'TABLEFOOTERLEFT' && (action.sketchup ? sketchUpBrowser : true)">
                                    <ButtonAction :key="'crudfooter-'+action.name+'-'+actionIndex"
                                                  :action="action" :disabled="actionDisabled(action)" :parameter-data="parameterData" forward-events
                                                  v-on:forward-event="handleForwardedActionEvent(action, $event)"
                                                  v-if="action.type === 'BUTTON'"/>
                                    <DropDownAction :key="'crudfooter-'+action.name+'-'+actionIndex"
                                                    :action="action" :disabled="actionDisabled(action)" forward-events
                                                    v-on:forward-event="handleForwardedActionEvent(action, $event)"
                                                    v-if="action.type === 'DROPDOWN'"/>
                                </template>
                            </template>
                        </td>
                        <td>
                            <div class="float-right">
                                <div class="input-group input-group-sm">
                                    <template v-for="(action,actionIndex) in crudActions">
                                        <template v-if="!action.hideintable && action.placement === 'TABLEFOOTERRIGHT' && (action.sketchup ? sketchUpBrowser : true)">
                                            <div :key="action.name+'-'+actionIndex" class="input-group-append">
                                                <ButtonAction :key="'crudfooter-'+action.name+'-'+actionIndex"
                                                              :action="action" :disabled="actionDisabled(action)" :parameter-data="parameterData" forward-events
                                                              v-on:forward-event="handleForwardedActionEvent(action, $event)"
                                                              v-if="action.type === 'BUTTON'"/>
                                                <DropDownAction :key="'crudfooter-'+action.name+'-'+actionIndex"
                                                                :action="action" :disabled="actionDisabled(action)" forward-events
                                                                v-on:forward-event="handleForwardedActionEvent(action, $event)"
                                                                v-if="action.type === 'DROPDOWN'"/>
                                            </div>
                                        </template>
                                    </template>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </template>

    </div>
</template>

<script>
import * as _ from "debounce";
import ButtonAction from "@/components/actions/ButtonAction";
import DropDownAction from "@/components/actions/DropDownAction";
import Field from "@/components/fields/Field";
import FieldTitle from "@/mixins/FieldTitle";
import RouteAction from "@/components/actions/RouteAction";
import ToggleAction from "@/components/actions/ToggleAction.vue";

/* global sketchup:false */

export default {
    name: "Table",
    components: {
        ButtonAction,
        DropDownAction,
        Field,
        RouteAction,
        ToggleAction,
    },
    props: {
        actions: {type: Array, default: null},
        api: {type: String, default: null},
        autofocus: {type: Boolean, default: false},
        bodyClass: {type: String, default: 'st-fadein-content'},
        bodyStyle: {type: String, default: null},
        cardClass: {type: String, default: null},
        cardStyle: {type: String, default: null},
        collapsed: {type: Boolean, default: false},
        collapsible: {type: Boolean, default: false},
        fields: {type: Array, default: null},
        footerClass: {type: String, default: null},
        footerStyle: {type: String, default: null},
        forwardEvents: {type: Boolean, default: false},
        headerClass: {type: String, default: null},
        headerStyle: {type: String, default: null},
        highlightRowId: {type: Number, default: null},
        highlightRowsOnClick: {type: Boolean, default: false},
        linesPerPage: {type: Number, default: 20},
        noActionsLabel: {type: Boolean, default: false},
        noStateLoading: {type: Boolean, default: false},
        paginationAlwaysVisible: {type: Boolean, default: false},
        parameterData: {type: Object, default: null},
        reloadTable: {type: Number, default: 0},
        reloadTableData: {type: Number, default: 0},
        rows: {type: Array, default: null},
        selection: {type: Boolean, default: false},
        state: {type: Object, default: () => ({})},
        thActionsClass: {type: String, default: null},
        thActionsStyle: {type: String, default: null},
        titleIcon: {type: Array, default: null},
        title: {type: String, default: null},
        totals: {type: Object, default: null},
        totalFields: {type: Array, default: null},
        withFilter: {type: Boolean, default: false},
        withPagination: {type: Boolean, default: false},
    },
    mixins: [FieldTitle],
    data() {
        return {
            pageLoaded: false,
            sketchUpBrowser: (typeof sketchup !== "undefined"),
            isCollapsed: !!this.collapsed,
            reloadRow: 0,
            selectedRowId: this.highlightRowId,
            filter: '',
            currentPageLink: this.api,
            crudActions: this.actions ? this.actions : [],
            crudFields: this.fields ? this.fields : [],
            filteredRows: this.rows,
            tableRows: null,
            links: [],
            meta: null,
            numberOfSelectedRows: 0,
            selectAll: false,
            showHeader: false,
            showFooter: false,
            showActions: false,
        }
    },
    watch: {
        reloadTable: function () {
            this.reloadData();
        },
        reloadTableData: function () {
            this.reloadData();
        }
    },
    computed: {
        colSpan: function () {
            let tableFields = 0;
            this.fields.forEach(field => {
                if (!field.hideintable && (field.showintable || field.editintable)) {
                    tableFields++;
                }
            });
            return tableFields - this.totalFields.length;
        },
    },
    created() {
        this.setStateLoading(true);
        if (this.api) {
            this.crud();
        } else {
            this.filterRows(true);
            this.selectRow(this.highlightRowId);
            this.checkLayout();
            this.pageLoaded = true;
            this.setStateLoading(false);
        }
    },
    methods: {
        actionDisabled(action) {
            if (this.state.disabled) {
                return true;
            }
            if (action.enable === 'selection') {
                return !(this.selection || this.numberOfSelectedRows > 0);
            }
            return false;
        },
        changePage(link) {
            this.setStateLoading(true);
            this.currentPageLink = link;
            this.crud();
        },
        changeStateInTable(field) {
            field.stateintable = field.stateintable === 0 ? 1 : field.stateintable === 1 ? 2 : 0;
            this.filterRows(true);
            this.$refs.filter.focus();
        },
        checkLayout() {
            let show = !!this.title;
            if (!show) {
                this.crudActions.forEach(action => {
                    if (!action.hideintable && (action.placement === 'TABLEHEADERLEFT' || action.placement === 'TABLEHEADERRIGHT')) {
                        show = true;
                    }
                });
            }
            this.showHeader = show;

            show = false;
            this.crudActions.forEach(action => {
                if (!action.hideintable && (action.placement === 'TABLEFOOTERLEFT' || action.placement === 'TABLEFOOTERRIGHT')) {
                    show = true;
                }
            });
            this.showFooter = show;

            show = false;
            this.crudActions.forEach(action => {
                if (!action.hideintable && action.placement === 'TABLEROW') {
                    show = true;
                }
            });
            this.showActions = show;
        },
        countSelectedRows() {
            this.numberOfSelectedRows = 0;
            if (this.api) {
                this.tableRows.forEach(row => {
                    if (row.selected) {
                        this.numberOfSelectedRows++;
                    }
                });
            } else {
                this.rows.forEach(row => {
                    if (row.selected) {
                        this.numberOfSelectedRows++;
                    }
                });
            }
        },
        collapseTable() {
            if (this.collapsible) {
                this.isCollapsed = !this.isCollapsed;
                this.$emit('collapse-table', this.isCollapsed);
            }
        },
        crud() {
            this.$http.post(this.currentPageLink, {filter: this.filter}, {}).then((res) => {
                this.crudFields = res.data.fields;
                this.crudActions = res.data.actions ? res.data.actions : [];
                let tmp = res.data.data;
                if (tmp) {
                    tmp.forEach(row => {
                        row.selected = false;
                    })
                }
                this.tableRows = tmp;
                this.numberOfSelectedRows = 0;
                if (this.tableRows) {
                    this.links = res.data.links;
                    this.meta = res.data.meta;
                    this.currentPageLink = this.meta.path;
                }

                this.checkLayout();

                this.pageLoaded = true;
                this.setStateLoading(false);
            }).catch((error) => {
                console.log("Table:crud():error:", error);
            });
        },
        debounceInput: _.debounce(function () {
            if (this.api) {
                this.crud();
            } else {
                this.filterRows(true);
            }
        }, 450),
        filterRows(resetPagination) {
            this.filteredRows = [];
            this.rows.forEach(row => {
                if (this.filterStateInTable(row) && this.filterText(row)) {
                    this.filteredRows.push(row);
                }
            });
            if (this.withPagination) {
                this.setPage(resetPagination ? 1 : this.meta.current_page);
            } else {
                this.tableRows = this.filteredRows;
            }
        },
        filterStateInTable(row) {
            let found = true;
            this.fields.forEach(field => {
                if ('stateintable' in field && !field.hideintable && (field.showintable || field.editintable)) {
                    if (field.stateintable === 1 && (row[field.name] === "" || row[field.name] === null)) {
                        found = false;
                    } else if (field.stateintable === 2 && (row[field.name] !== "" && row[field.name] !== null)) {
                        found = false;
                    }
                }
            });
            return found;
        },
        filterText(row) {
            if (this.filter) {
                let found = false;
                this.fields.forEach(field => {
                    if (!field.hideintable && (field.showintable || field.editintable)) {
                        if (String(row[field.name]).toLowerCase().indexOf(this.filter.toLowerCase()) !== -1) {
                            found = true;
                        }
                    }
                });
                return found;
            }
            return true;
        },
        firstPage() {
            if (this.api) {
                this.changePage(this.links.first);
            } else {
                this.setPage(1);
            }
        },
        handleForwardedActionEvent(action, eventData) {
            if (action.resethighlightrow) {
                this.selectRow(null);
            }
            if (action.enable === 'selection' || action.payload === 'selection') {
                let ids = [];
                if (this.api) {
                    this.tableRows.forEach(row => {
                        if (row.selected) {
                            ids.push(row.id);
                        }
                    });
                } else {
                    this.rows.forEach(row => {
                        if (row.selected) {
                            ids.push(row.id);
                        }
                    });
                }
                eventData.data.ids = ids;
            } else if (action.payload === 'filtered') {
                let ids = [];
                if (this.api) {
                    this.tableRows.forEach(row => {
                        ids.push(row.id);
                    });
                } else {
                    this.filteredRows.forEach(row => {
                        ids.push(row.id);
                    });
                }
                eventData.data.ids = ids;
            }
            if (this.forwardEvents) {
                this.$emit('forward-event', eventData);
            } else {
                this.$emit(eventData.event, eventData.data);
            }
        },
        handleForwardedActionEventForRow(action, eventData, row) {
            if (action.highlightrow) {
                this.selectRow(row.id);
            }
            eventData.data = row;
            this.handleForwardedActionEvent(action, eventData);
        },
        handleForwardedFieldEvent(field, eventData) {
            if (this.forwardEvents) {
                this.$emit('forward-event', eventData);
            } else {
                this.$emit(eventData.event, eventData.data);
            }
        },
        inlineEdit(index, data) {
            if (this.api) {
                this.tableRows[index] = data.data;
                this.reloadRow++;
                this.setStateLoading(false);
            } else {
                this.$emit('inline-edit', data);
            }
        },
        lastPage() {
            if (this.api) {
                this.changePage(this.links.last);
            } else {
                this.setPage(this.meta.last_page);
            }
        },
        onClickTableRow(row) {
            this.$emit('click-row', row);
            if (this.highlightRowsOnClick) {
                this.selectRow(row.id);
            }
        },
        nextPage() {
            if (this.api) {
                this.changePage(this.links.next);
            } else {
                this.setPage(this.meta.current_page + 1);
            }
        },
        previousPage() {
            if (this.api) {
                this.changePage(this.links.prev);
            } else {
                this.setPage(this.meta.current_page - 1);
            }
        },
        reloadData() {
            this.filterRows(false);
        },
        selectRow(rowId) {
            this.selectedRowId = rowId;
            let row = null;
            if (this.tableRows) {
                let idx = this.tableRows.findIndex(row => row.id === this.selectedRowId);
                if (idx !== -1) {
                    row = this.tableRows[idx];
                }
            }
            this.$emit('highlight-row', row);
        },
        setPage(page) {
            this.setStateLoading(true);
            let last_page = Math.ceil(this.filteredRows.length / this.linesPerPage);
            this.meta = {
                total: this.filteredRows.length,
                last_page: last_page,
                per_page: this.linesPerPage,
                current_page: Math.min(Math.max(1, page), last_page),
            }
            this.meta.from = (this.meta.current_page - 1) * this.meta.per_page + 1;
            this.meta.to = Math.min((this.meta.from + this.meta.per_page - 1), this.filteredRows.length);
            this.tableRows = this.filteredRows.slice(this.meta.from - 1, this.meta.to);
            this.setStateLoading(false);
        },
        setStateLoading(value) {
            if (!this.noStateLoading) {
                this.state.loading = value;
            }
        },
        toggleAll() {
            if (this.api) {
                this.tableRows.forEach(row => {
                    row.selected = this.selectAll;
                })
            } else {
                this.rows.forEach(row => {
                    row.selected = this.selectAll;
                })
            }
            this.countSelectedRows();
            this.$emit('toggle-row', this.numberOfSelectedRows);
        },
        toggleRow() {
            this.countSelectedRows();
            this.$emit('toggle-row', this.numberOfSelectedRows);
        },
    }
}
</script>

<style scoped>
.btn.disabled, .btn:disabled {
    opacity: 0.15;
}

.table-highlight > tbody > tr.selected-row {
    outline: 1px solid darkslategray;
    background: lightgreen;
}
</style>
